import { Clone } from '@react-three/drei'

export default function Trireme(props) {
	Trireme.defaultProps = {
		position: [0, 0, 0],
		scale: [1, 1, 1],
		rotation: [0, 0, 0],
	}

	return (
		<>
			<Clone
				ref={props.inputRef}
				object={props.model.scene}
				position={props.position}
				scale={props.scale}
				rotation={props.rotation}
			/>
		</>
	);
}

