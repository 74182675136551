import React, { StrictMode, useEffect } from 'react';
import ThreeFiberCanvas from './Components/ThreeFiberCanvas/ThreeFiberCanvas.jsx';
import ThreeFiberCanvasWithDebug from './Components/ThreeFiberCanvas/ThreeFiberCanvasWithDebug.jsx';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from './Components/NotFound/NotFound.jsx';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { Leva } from 'leva';
import { VideoPlayback } from './Components/VideoPlayback/VideoPlayback.jsx';
import { useDetectGPU } from '@react-three/drei';
import { useZStore } from './store/store.js';

const IS_DEBUG = false;

// Imma move this eventually, still thinking where
var darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#b8860b'
		}
	},
	typography: {
		fontFamily: 'serif'
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					backgroundColor: '#323232',
					padding: '1rem',
					margin: '1rem',
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&:hover': {

					},
					fontSize: '14px',
					fontWeight: 'bold',
					borderRadius: '1px',
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: '5px',
					borderRadius: '1px',
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '1px'
				}
			}
		}
	}
});

darkTheme = responsiveFontSizes(darkTheme);

//// Temp for easier deployed/local authentication skip
const baseRoute =
	// import.meta.env.VITE_HAS_AUTH
	// 	?
	// 	(
	// 		<ProtectedRoute>
	// 			<ThreeFiberCanvas />
	// 			<VideoPlayback />
	// 		</ProtectedRoute>
	// 	)
	// 	:
	(
		<React.Fragment>
			{IS_DEBUG ? <ThreeFiberCanvasWithDebug /> : <ThreeFiberCanvas />}
			<VideoPlayback />
		</React.Fragment>
	);


function App(props) {
	return (
		<ThemeProvider theme={darkTheme}>
			<Leva
				flat // default = false,  true removes border radius and shadow
				oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
				hideTitleBar // default = false, hides the GUI header
				collapsed // default = false, when true the GUI is collpased
				hidden={true} // default = false, when true the GUI is hidden
			/>
			<CssBaseline />
			<div className="App" style={{ height: '100%' }}>
				<Routes>
					<Route path="/" element={
						baseRoute
					} />
					{/* <Route path="/login" element={<LoginComponent />} /> */}
					{/* <Route path="/registration" element={<RegistrationComponent />} /> */}
					{/* <Route path="/invite" element={<InviteComponent />} /> */}
					<Route path='*' element={<NotFound />} />
				</Routes>
			</div >
		</ThemeProvider>
	);
}

export default App;
