import { useControls } from "leva";
import { useEffect } from "react";

export const levaHistory = (props) => {

    useEffect(() => {

    }, [])

    return useControls('History', {
        position:
        {
            value: props.position.value,
            step: 0.01,
        }
    },
        {
            collapsed: true
        });
}