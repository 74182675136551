import { useControls } from "leva";


export const LevaAmbientLight = (ref, props) => {
	return useControls('Ambient Light', {
		intensity: {
			value: props.intensity,
			min: 0,
			max: 10,
			step: 0.01
		},
		color: {
			value: props.color,
			onChange: (v) => {
				ref.current.color.set(v); // Set the color of the light
			}
		}
	},

		{
			collapsed: true
		});
}
