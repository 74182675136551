import { Html } from "@react-three/drei"
import { useRef } from "react"
import './ui-overlay.scss'
import { levaMainPageTitle } from "./../ThreeFiberCanvas/MelinasDreamExperience/LevaDebug/LevaMainPageTitle"
// import { Logo } from "./../../assets/images/Logo2NoBackgroundCropped.png";

export function MainPageTitle(props) {
	MainPageTitle.defaultProps = {
		position: [-0.018, 2.50, -0.357],
	}

	const mainPageTitleRef = useRef()

	const mainPageTitleProps = { ...MainPageTitle.defaultProps, ...props }

	const { position } = levaMainPageTitle(mainPageTitleProps);

	// top left text stuff
	const textArrTmpTop = "A Spartan Dream".toUpperCase().split(' ');
	const textArrDesc = "This is where the intro statement goes"
	const textCharArrTop = [];


	for (let i = 0; i < textArrTmpTop.length; i++) {
		textCharArrTop.push(textArrTmpTop[i].split(''));
	}

	return <>
		<div ref={mainPageTitleRef}
			className={'main-page-title-html-content-wrapper'}
			onClick={props.playback}
			style={{
				opacity: 1,
				zIndex: 999
			}}
		>
			<div>
				<div className={'melinas-dream-title'}>
					{/* <img src="/assets/images/TextLogo.png" className={`melinas__word`} alt="Odyssey Entertainment LLC" /> */}
					{/* {
						textCharArrTop.map((word, i) => <div key={`${i}`} className={`melinas__word`}>
							{
								textCharArrTop[i].map((char, j) => <span key={`${j}`} className={`melinas__word__char`} style={{ opacity: 0 }}>
									{
										char == "&nbsp;" ? <span>&nbsp;</span> : char
									}
								</span>)
							}
						</div>)
					} */}
				</div>
			</div>
		</div>
	</>
}