import './svgs.scss';

export const SoundOff = () => (
	<svg version="1.1" id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="10px" y="10px" viewBox="0 0 122.88 120.97"
		className="sound__off"
	>
		<defs>
			<filter id="shadow1" x="-20%" y="-20%" width="140%" height="140%">
				<feDropShadow dx="-4" dy="4" stdDeviation="4" floodColor="rgba(0, 0, 0, 1)" />
			</filter>
		</defs>
		<g filter="url(#shadow1)">
			<path fill="#ffffff"
				d="M7.02,28.81h28.65c0.6,0,1.09,0.49,1.09,1.09v44.09L17.76,93H7c-3.85,0-7-3.15-7-7V35.83 
			 C0,31.97,3.16,28.81,7.02,28.81L7.02,28.81z M111.29,6.02l11.59,11.59l-93.17,93.17L18.12,99.19L111.29,6.02L111.29,6.02z M42.33,27.67c16.7-9.16,33.4-18.32,50.09-27.48c1.6-0.88,3.32,1.49,3.32,3.32v11.5L41.66,69.1V28.34 C41.66,27.97,42.01,27.85,42.33,27.67L42.33,27.67z M95.74,51.66v65.6c0,1.92-1.82,4.45-3.5,3.5L50.29,97.11L95.74,51.66 L95.74,51.66z" />
		</g>
	</svg>
)


