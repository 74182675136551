// import * as THREE from 'three'
// import React, { useRef, useEffect } from 'react'
// import { useTexture } from '@react-three/drei'

// export default function SkyBox(props) {

//     Skybox.defaultProps = {
//         useBackground: false,
//         useSkybox: true
//     }

//     const skyboxRef = useRef()

//     const path = '../../../../assets/images/skybox/'
//     const images = ['right', 'left', 'top', 'bot', 'front', 'back']
//     const ext = '.jpg'
//     const imagePaths = images.map(img => path + 'epicSunsetGolden/' + img + ext)

//     const textures = useTexture(imagePaths)

//     const materials = [
//         new THREE.MeshBasicMaterial({ map: textures[0], side: THREE.BackSide }),
//         new THREE.MeshBasicMaterial({ map: textures[1], side: THREE.BackSide }),
//         new THREE.MeshBasicMaterial({ map: textures[2], side: THREE.BackSide }),
//         new THREE.MeshBasicMaterial({ map: textures[3], side: THREE.BackSide }),
//         new THREE.MeshBasicMaterial({ map: textures[4], side: THREE.BackSide }),
//         new THREE.MeshBasicMaterial({ map: textures[5], side: THREE.BackSide })
//     ]

//     function Skybox() {
//         const skyboxGeometry = new THREE.BoxGeometry(1000, 1000, 1000)

//         return <mesh ref={skyboxRef} material={materials} geometry={skyboxGeometry} rotation={[0, Math.PI, 0]} />
//     }

//     return <>
//         <Skybox />
//     </>

// }

import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'

function Skybox({ materials }) {
    const skyboxRef = useRef()

    const skyboxGeometry = new THREE.BoxGeometry(1000, 1000, 1000)

    return <mesh ref={skyboxRef} material={materials} geometry={skyboxGeometry} rotation={[0, Math.PI, 0]} />
}

export default function SkyBox(props) {

    SkyBox.defaultProps = {
        useBackground: false,
        useSkybox: true
    }

    const path = '/assets/images/skybox/'
    const images = ['right', 'left', 'top', 'bot', 'front', 'back']
    const ext = '.jpg'
    const imagePaths = images.map(img => path + 'epicSunsetGolden/' + img + ext)

    const textures = useLoader(TextureLoader, imagePaths)

    const materials = [
        new THREE.MeshBasicMaterial({ map: textures[0], side: THREE.BackSide }),
        new THREE.MeshBasicMaterial({ map: textures[1], side: THREE.BackSide }),
        new THREE.MeshBasicMaterial({ map: textures[2], side: THREE.BackSide }),
        new THREE.MeshBasicMaterial({ map: textures[3], side: THREE.BackSide }),
        new THREE.MeshBasicMaterial({ map: textures[4], side: THREE.BackSide }),
        new THREE.MeshBasicMaterial({ map: textures[5], side: THREE.BackSide })
    ]

    return <Skybox materials={materials} />
}
