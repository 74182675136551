import { useControls } from "leva";

export const levaMainPageTitle = (props) => {
    return useControls('Main Page Title', {
        position: {
            value: props.position,
            step: 0.005
        }
    },
        { collapsed: true })
}