import { CloseSVG } from "../Svgs/Close"
import { forwardRef } from "react"
import { useZStore } from "../../store/store";
import { useEffect, useState, useRef } from "react";
import { Carrot } from "../../assets/svg/Carrot.jsx";

export const ModalOuter = forwardRef((props, ref) => {

    const updateShowModal = useZStore(state => state.updateShowModal);
    const updateModalContent = useZStore(state => state.updateModalContent);
    const [isHistorySection, setIsHistorySection] = useState(false)
    const [isInfoSection, setIsInfoSection] = useState(false)
    const modalContent = useZStore(state => state.modalContent);
    const innerModalRef = useRef();

    // Function to handle click outside of modal
    const handleClickOutside = (event) => {
        if (innerModalRef.current && !innerModalRef.current.contains(event.target)) {
            updateShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const unsubscribeSection = useZStore.subscribe(
            (state) => state.section,
            (value) => {
                if (value === 'history') {
                    setIsHistorySection(true)
                    setIsInfoSection(false)
                } else {
                    setIsHistorySection(false)
                    setIsInfoSection(true)
                }
            })
        return () => {
            unsubscribeSection()
        }
    }, [])

    useEffect(() => {
        const unsubscribeModalContent = useZStore.subscribe(
            (state) => state.modalContent,
            (value) => {
                if (value === 'Elgin Marbles') {
                    updateModalContent('Elgin Marbles')
                }
                else if (value == 'Vaphio Cups') {
                    updateModalContent('Vaphio Cups')
                } else {
                    updateModalContent('Info')
                }
            })
        return () => {
            unsubscribeModalContent()
        }
    }, [])

    return (
        <>
            <div
                ref={ref}
                className="modal-outer"
            >
                <div className="modal-inner" ref={innerModalRef}>
                    <div className="modal-exit close-icon" onClick={() => updateShowModal(false)}>
                        <CloseSVG style={{ zIndex: 99999 }} />
                    </div>

                    <div className="modal-content">
                        <div className="modal-content-body">
                            {props.children}
                        </div>
                    </div>

                    {/* {
                        isHistorySection &&
                        <div className="modal-change-page">
                            <div className="modal-change-page-inner">
                                <div className="modal-page-index">
                                    {
                                        modalContent == 'Elgin Marbles' ?
                                            <div onClick={() => updateModalContent('Vaphio Cups')} className="modal-page-index-inner">
                                                <div className="modal-page-index-inner-text-right">
                                                    Vaphio Cups
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Carrot />
                                                </div>
                                            </div>
                                            :
                                            <div onClick={() => updateModalContent('Elgin Marbles')} className="modal-page-index-inner">
                                                <div style={{ transform: 'rotateY(180deg)' }}>
                                                    <Carrot />
                                                </div>
                                                <div className="modal-page-index-inner-text-left">
                                                    Elgin Marbles
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {
                        isInfoSection &&
                        <div className="modal-change-page">
                            <div className="modal-change-page-inner">
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        </>
    )
});