import { useGLTF, useTexture, useAnimations } from '@react-three/drei'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { levaTemple } from '../LevaDebug/LevaTemple'
import * as THREE from 'three'
import { useThree } from '@react-three/fiber'
import { useZStore } from '../../../../store/store'

export default function Temple(props) {
	const isMobile = useZStore(state => state.isMobile);

	Temple.defaultProps = {
		position: [0, 0, 0],
		rotation: [0, 0, 0],
		scale: [1, 1, 1],
	}
	let temple = useGLTF('../../../../assets/models/temple.glb')
	let animatedObjects = useGLTF('../../../../assets/models/animatedObjects.glb')
	let sectionObjects;

	if (!isMobile) {
		sectionObjects = useGLTF('../../../../assets/models/sceneObjects.glb');
	}

	const { scene } = useThree()
	let { nodes, materials, animations } = useGLTF('../../../../assets/models/animatedObjects.glb')
	let { ref, mixer, names, actions, clips } = useAnimations(animations, animatedObjects.scene)

	//Temple textures
	const bakedBases = useTexture('../../../../assets/models/BakedBases.jpg')
	bakedBases.flipY = false

	const bakedColumns = useTexture('../../../../assets/models/BakedColumns.jpg')
	bakedColumns.flipY = false

	const bakedFloor = useTexture('../../../../assets/models/BakedFloor.jpg')
	bakedFloor.flipY = false

	const bakedGrass = useTexture('../../../../assets/models/BakedGrass4.png')
	bakedGrass.flipY = false

	const bakedCanvas = useTexture('../../../../assets/models/BakedCanvas3.jpg')
	bakedCanvas.flipY = false

	const bakedRope = useTexture('../../../../assets/models/BakedRope5.jpg')
	bakedRope.flipY = false

	//Section object textures
	const bakedBlock = useTexture('../../../../assets/models/BakedBlock.jpg')
	bakedBlock.flipY = false
	bakedBlock.colorSpace = THREE.SRGBColorSpace

	const bakedBox1 = useTexture('../../../../assets/models/Box1.jpg')
	bakedBox1.flipY = false
	bakedBox1.colorSpace = THREE.SRGBColorSpace

	const bakedBox2 = useTexture('../../../../assets/models/Box2.jpg')
	bakedBox2.flipY = false
	bakedBox2.colorSpace = THREE.SRGBColorSpace

	const bakedCamera = useTexture('../../../../assets/models/BakedCameraFull.jpg')
	bakedCamera.flipY = false
	bakedCamera.colorSpace = THREE.SRGBColorSpace

	const bakedClapboard = useTexture('../../../../assets/models/BakedClapboard.jpg')
	bakedClapboard.flipY = false
	bakedClapboard.colorSpace = THREE.SRGBColorSpace

	const bakedHelmet = useTexture('../../../../assets/models/BakedHelmet.jpg')
	bakedHelmet.flipY = false
	bakedHelmet.colorSpace = THREE.SRGBColorSpace

	const bakedShield = useTexture('../../../../assets/models/BakedShield.jpg')
	bakedShield.flipY = false
	bakedShield.colorSpace = THREE.SRGBColorSpace

	const bakedMarbles = useTexture('../../../../assets/models/BakedMarbles.jpg')
	bakedMarbles.flipY = false
	bakedMarbles.colorSpace = THREE.SRGBColorSpace

	const bakedSandbag = useTexture('../../../../assets/models/BakedSandbag.jpg')
	bakedSandbag.flipY = false
	bakedSandbag.colorSpace = THREE.SRGBColorSpace

	const bakedTripod = useTexture('../../../../assets/models/BakedTripodFull.jpg')
	bakedTripod.flipY = false
	bakedTripod.colorSpace = THREE.SRGBColorSpace

	const bakedChair = useTexture('../../../../assets/models/BakedChair.jpg')
	bakedChair.flipY = false
	bakedChair.colorSpace = THREE.SRGBColorSpace

	useLayoutEffect(() => {
		if (!isMobile) {
			sectionObjects.scene.traverse((child) => {
				if (child.isMesh) {
					if (child.name === 'block') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedBlock })
					}
					if (child.name === 'box1') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedBox2 })
					}
					if (child.name === 'box2') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedBox1 })
					}
					if (child.name === 'camera') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedCamera })
					}
					if (child.name === 'clapboard') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedClapboard })
					}
					if (child.name === 'helmet001') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedHelmet })
					}
					if (child.name === 'shield001') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedShield })
					}
					if (child.name === 'marbles001') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedMarbles })
					}
					if (child.name === 'sandbag') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedSandbag })
					}
					if (child.name === 'tripod') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedTripod })
					}
					if (child.name === 'Directors_Chair_02') {
						child.material = new THREE.MeshBasicMaterial({ map: bakedChair })
					}
				}
			})
		}

		animatedObjects.scene.traverse((child) => {
			if (child.isMesh) {
				if (child.name === 'grass') {
					child.material = new THREE.MeshBasicMaterial({ map: bakedGrass, transparent: true })
				}
				if (child.name === 'rope002') {
					child.material = new THREE.MeshBasicMaterial({ map: bakedRope })
				}
			}
		})
		actions['ropeSway1'].play()
		actions['grassWind'].play()
	}, [actions])

	return <>
		<group position={[0, 0.75, 0.5]} scale={[1.5, 1.5, 1.5]}>
			{/* Temple Objects */}
			<mesh geometry={temple.nodes.bases.geometry}>
				<meshBasicMaterial map={bakedBases} />
			</mesh>

			<mesh geometry={temple.nodes.columns.geometry}>
				<meshBasicMaterial map={bakedColumns} />
			</mesh>

			<mesh geometry={temple.nodes.floor.geometry} receiveShadow={true}>
				<meshBasicMaterial map={bakedFloor} />
			</mesh>

			<mesh geometry={temple.nodes.grass.geometry}>
				<meshBasicMaterial map={bakedGrass} transparent={true} />
			</mesh>

			<mesh geometry={temple.nodes.canvas.geometry} receiveShadow={true}>
				<meshStandardMaterial map={bakedCanvas} />
			</mesh>

			<mesh geometry={temple.nodes.rope.geometry}>
				<meshBasicMaterial map={bakedRope} />
			</mesh>

			{!isMobile ? <primitive object={sectionObjects.scene} /> : <></>}

			<primitive object={animatedObjects.scene} />

		</group >


	</>
}


// import { useGLTF, useTexture, useAnimations } from '@react-three/drei';
// import { useEffect, useLayoutEffect } from 'react';
// import * as THREE from 'three';
// import { useThree } from '@react-three/fiber';
// import { useZStore } from '../../../../store/store';

// Temple.defaultProps = {
// 	position: [0, 0, 0],
// 	rotation: [0, 0, 0],
// 	scale: [1, 1, 1],
// };

// // Load textures once at the module level

// export default function Temple(props) {
// 	const isMobile = useZStore((state) => state.isMobile);

// 	// Load all GLTF models
// 	const temple = useGLTF('../../../../assets/models/temple.glb');
// 	const animatedObjects = useGLTF('../../../../assets/models/animatedObjects.glb');
// 	const sectionObjects = useGLTF('../../../../assets/models/sceneObjects.glb');

// 	const { scene } = useThree();
// 	const { ref, mixer, names, actions, clips } = useAnimations(animatedObjects.animations, animatedObjects.scene);

// 	const loadTexture = (path, flipY = false, colorSpace = null) => {
// 		const texture = useTexture(path);
// 		texture.flipY = flipY;
// 		if (colorSpace) texture.colorSpace = colorSpace;
// 		return texture;
// 	};

// 	const textures = {
// 		bakedBases: loadTexture('../../../../assets/models/BakedBases.jpg'),
// 		bakedColumns: loadTexture('../../../../assets/models/BakedColumns.jpg'),
// 		bakedFloor: loadTexture('../../../../assets/models/BakedFloor.jpg'),
// 		bakedGrass: loadTexture('../../../../assets/models/BakedGrass4.png'),
// 		bakedCanvas: loadTexture('../../../../assets/models/BakedCanvas3.jpg'),
// 		bakedRope: loadTexture('../../../../assets/models/BakedRope5.jpg'),
// 		bakedBlock: loadTexture('../../../../assets/models/BakedBlock.jpg', false, THREE.SRGBColorSpace),
// 		bakedBox1: loadTexture('../../../../assets/models/Box1.jpg', false, THREE.SRGBColorSpace),
// 		bakedBox2: loadTexture('../../../../assets/models/Box2.jpg', false, THREE.SRGBColorSpace),
// 		bakedCamera: loadTexture('../../../../assets/models/BakedCameraFull.jpg', false, THREE.SRGBColorSpace),
// 		bakedClapboard: loadTexture('../../../../assets/models/BakedClapboard.jpg', false, THREE.SRGBColorSpace),
// 		bakedHelmet: loadTexture('../../../../assets/models/BakedHelmet.jpg', false, THREE.SRGBColorSpace),
// 		bakedShield: loadTexture('../../../../assets/models/BakedShield.jpg', false, THREE.SRGBColorSpace),
// 		bakedMarbles: loadTexture('../../../../assets/models/BakedMarbles.jpg', false, THREE.SRGBColorSpace),
// 		bakedSandbag: loadTexture('../../../../assets/models/BakedSandbag.jpg', false, THREE.SRGBColorSpace),
// 		bakedTripod: loadTexture('../../../../assets/models/BakedTripodFull.jpg', false, THREE.SRGBColorSpace),
// 		bakedChair: loadTexture('../../../../assets/models/BakedChair.jpg', false, THREE.SRGBColorSpace),
// 	};

// 	useLayoutEffect(() => {
// 		const assignMaterials = (scene, textureMap) => {
// 			scene.traverse((child) => {
// 				if (child.isMesh) {
// 					const materialMap = {
// 						block: textureMap.bakedBlock,
// 						box1: textureMap.bakedBox2,
// 						box2: textureMap.bakedBox1,
// 						camera: textureMap.bakedCamera,
// 						clapboard: textureMap.bakedClapboard,
// 						helmet001: textureMap.bakedHelmet,
// 						shield001: textureMap.bakedShield,
// 						marbles001: textureMap.bakedMarbles,
// 						sandbag: textureMap.bakedSandbag,
// 						tripod: textureMap.bakedTripod,
// 						Directors_Chair_02: textureMap.bakedChair,
// 						grass: textureMap.bakedGrass,
// 						rope002: textureMap.bakedRope,
// 					};
// 					if (materialMap[child.name]) {
// 						child.material = new THREE.MeshBasicMaterial({ map: materialMap[child.name] });
// 						if (child.name === 'grass') child.material.transparent = true;
// 					}
// 				}
// 			});
// 		};

// 		if (sectionObjects.scene && !isMobile) {
// 			assignMaterials(sectionObjects.scene, textures);
// 		}
// 		assignMaterials(animatedObjects.scene, textures);

// 		actions['ropeSway1'].play();
// 		actions['grassWind'].play();
// 	}, [actions, sectionObjects, animatedObjects.scene, isMobile]);

// 	return (
// 		<group position={[0, 0.75, 0.5]} scale={[1.5, 1.5, 1.5]}>
// 			{/* Temple Objects */}
// 			<mesh geometry={temple.nodes.bases.geometry}>
// 				<meshBasicMaterial map={textures.bakedBases} />
// 			</mesh>
// 			<mesh geometry={temple.nodes.columns.geometry}>
// 				<meshBasicMaterial map={textures.bakedColumns} />
// 			</mesh>
// 			<mesh geometry={temple.nodes.floor.geometry} receiveShadow={true}>
// 				<meshBasicMaterial map={textures.bakedFloor} />
// 			</mesh>
// 			<mesh geometry={temple.nodes.grass.geometry}>
// 				<meshBasicMaterial map={textures.bakedGrass} transparent={true} />
// 			</mesh>
// 			<mesh geometry={temple.nodes.canvas.geometry} receiveShadow={true}>
// 				<meshStandardMaterial map={textures.bakedCanvas} />
// 			</mesh>
// 			<mesh geometry={temple.nodes.rope.geometry}>
// 				<meshBasicMaterial map={textures.bakedRope} />
// 			</mesh>
// 			{!isMobile && <primitive object={sectionObjects.scene} />}
// 			<primitive object={animatedObjects.scene} />
// 		</group>
// 	);
// }
