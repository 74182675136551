import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { levaHistory } from './../../LevaDebug/LevaHistory'
import './history.scss';
import { useZStore } from '../../../../../store/store';
import TouchAppIcon from '@mui/icons-material/TouchApp';

export default function History() {
	History.defaultProps = {
		position:
		{
			//0.98 for safari
			value: [0.73, 1.185, -0.5755],
			step: 0.01,
		},
	}
	const historyProps = { ...History.defaultProps };

	const { position } = levaHistory(historyProps)

	const updateShowModal = useZStore(state => state.updateShowModal)
	const updateModalContent = useZStore(state => state.updateModalContent)


	const handleMarblesClick = () => {
		updateModalContent('Elgin Marbles')
		updateShowModal(true)
	}

	const handleVaphioClick = () => {
		updateModalContent('Vaphio Cups')
		updateShowModal(true)
	}

	return (
		<>
			<Html
				position={position}
				transform
				zIndexRange={[1111, 0]}
				distanceFactor={0.1}
			>
				<div style={{ fontSize: '24px', letterSpacing: '0.1rem' }} className='history'>
					<ul className={'history-list'} style={{ opacity: 0 }}>
						<li onClick={() => handleMarblesClick()}>
							<h1>Elgin Marbles</h1>
							{/* <h1>Elgin Marbles</h1> */}
						</li>
						<li onClick={() => handleVaphioClick()}>
							<h1>Vaphio Cups</h1>
						</li>
					</ul>
				</div>
				{/* <div className='bottom-div'>
					<div className='animated-pointer-finger'>
						<TouchAppIcon style={{ fontSize: '3rem' }} />
					</div>
				</div> */}
			</Html>
		</>
	)
}
