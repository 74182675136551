import { OrbitControls } from '@react-three/drei'
import { Perf } from 'r3f-perf'
import World from './World/World.jsx'
import PostProcessing from './PostProcessing.jsx'
import { Intro } from "../Intro"
import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'

export const MelinasDreamExperience = (props) => {
	const { gl } = useThree();

	useEffect(() => {
		gl.toneMappingExposure = 1.2;
		gl.toneMapping = 4;
	}, []);

	return <>
		{/* <Perf position="top-right" /> */}
		{/* <OrbitControls /> */}

		{/* {props.isDebug ? <></> : <Intro ambientMusicRef={props.ambientMusicRef} />} */}
		<Intro ambientMusicRef={props.ambientMusicRef} />
		<World />
		{/* <PostProcessing /> */}
	</>
}