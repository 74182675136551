import React, { useState, useEffect, useRef } from 'react';
import { Gear } from '../../../../../Svgs/Gear';
import gsap from 'gsap';
import './scene-settings.scss';
import { useZStore } from '../../../../../../store/store';
import { SoundOn } from '../../../../../Svgs/SoundOn';
import { SoundOff } from '../../../../../Svgs/SoundOff';


export const SceneSettings = (props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const qualityLevels = ['low', 'medium', 'high'];
	const [quality, setQuality] = useState('medium'); // TODO

	const sceneSettingsRef = useRef()
	const optionSelectedRef = useRef()
	const menuRef = useRef()
	const gearRef = useRef()
	const animationDuration = 0.25;

	const menuAnimationRef = useRef()
	const gearAnimationRef = useRef()

	const isAudioMuted = useZStore(state => state.isAudioMuted)
	const setIsAudioMuted = useZStore(state => state.setIsAudioMuted)

	// close menu when clicking outside && play animation
	useEffect(() => {
		// if (isMenuOpen && menuAnimationRef.current && gearAnimationRef.current) {
		// 	menuAnimationRef.current.play()
		// 	gearAnimationRef.current.play()
		// 	document.removeEventListener("mousedown", handleClickOutside)
		// }
		// if (!isMenuOpen && menuAnimationRef.current && gearAnimationRef.current) {
		// 	menuAnimationRef.current.reverse()
		// 	gearAnimationRef.current.reverse()
		// 	document.addEventListener("mousedown", handleClickOutside)
		// };
	}, [isMenuOpen]);

	// intantiate menu animation management when component mounts
	useEffect(() => {
		menuRef.current.style.pointerEvents = 'all';

		// gearAnimationRef.current = gsap.to(gearRef.current, {
		// 	paused: true,
		// 	rotate: -120,
		// 	duration: animationDuration,
		// 	ease: 'power1.inOut'
		// })

		gsap.set(menuRef.current, { autoAlpha: 0 })

		menuAnimationRef.current = gsap.to(menuRef.current, {
			paused: true,
			autoAlpha: 1,
			duration: animationDuration,
			ease: 'power1.inOut',
			x: 0
		})
	}, [])

	// HANDLERS
	// toggle menu operations when clicking on gear
	const handleClickOutside = (event) => {
		if (!menuRef.current.contains(event.target) && !gearRef.current.contains(event.target)) {
			setIsMenuOpen(false)
		}
	}

	const handleMouseEnter = (e) => {
		// console.log("entered");
		const optionsNotSelected = document.querySelectorAll('.expanding-menu__option:not(.expanding-menu__option--selected)');
		if (e.target.tagName === 'P') {
			e.target = e.target.parentNode;
		}
		if (Array.from(optionsNotSelected).includes(e.target)) {
			optionSelectedRef.current.style.backgroundColor = 'transparent';
			e.target.style.backgroundColor = 'rgba(255,255,255,0.3)';
		}
	}

	const handleMouseLeave = (e) => {
		const optionsNotSelected = document.querySelectorAll('.expanding-menu__option:not(.expanding-menu__option--selected)');
		optionSelectedRef.current.style.backgroundColor = 'rgba(255,255,255,0.3)';
		optionsNotSelected.forEach(option => {
			option.style.backgroundColor = 'transparent';
		})
	}
	//END HANDLERS

	return (
		<div className="scene-settings" ref={sceneSettingsRef}>
			{/* <div className="gear-wrapper"
				onClick={() => setIsMenuOpen(!isMenuOpen)}
				ref={gearRef}>
				<Gear />
			</div> */}
			<div className="gear-wrapper" >
				<div onClick={() => {
					setIsAudioMuted(!isAudioMuted)
				}} >
					{isAudioMuted == true ? <SoundOff /> : <SoundOn />}
				</div>

			</div>
			<div className="expanding-menu" ref={menuRef} >
				{/* <div className="expanding-menu__label">
					<p>Graphics Quality - </p>
				</div>
				<div className="expanding-menu__options" >
					{qualityLevels.map(level => (
						<div
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							key={level}
							id={'option-' + level}
							style={level === qualityLevels[1] ? { borderLeft: '1px solid grey', borderRight: '1px solid grey' } : { borderLeft: 'none' }}
							className={`expanding-menu__option${level === quality ? '--selected' : ''}`}
							ref={level === quality ? optionSelectedRef : null}
							onClick={() => setQuality(level)}
						>
							<p>{level}</p>
						</div>
					))}
				</div> */}
			</div>
		</div>
	);
}
