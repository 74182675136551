import { BlobServiceClient } from "@azure/storage-blob";

export const getVideoSource = (video) => {
	const account = 'melinasdreamvideostorage';
	const sas = '?sp=r&st=2024-06-09T23:49:32Z&se=2028-04-12T07:49:32Z&spr=https&sv=2022-11-02&sr=c&sig=%2FHUJjSIXClxGROLRujkD1HutOR22ziAemK1nQa3azXE%3D';
	const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
	return blobServiceClient.getContainerClient('melinasdreamvideos').getBlockBlobClient(video).url;
}	

// const sas = '?sp=r&st=2023-03-10T00:24:06Z&se=2026-07-31T07:24:06Z&sv=2021-12-02&sr=c&sig=AZDCBhYX2DyBFcM0Am6fXbuOQtEamZZEgKwzCCmsUps%3D';
