import { useState } from "react";

// TODO: look into storing as cookie instead but for now is more than fine
export const useLocalStorage = (keyName, defaultValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const value = window.localStorage.getItem(keyName);
			if (value) {
				return JSON.parse(value);
			} else {
				window.localStorage.setItem(keyName, JSON.stringify(defaultValue));

				return defaultValue;
			}
		} catch (err) {
			return defaultValue;
		}
	});

	const setValue = (newValue) => {
		try {
			window.localStorage.setItem(keyName, JSON.stringify(newValue));
		} catch (err) { }
		setStoredValue(newValue);
	};

	return [storedValue, setValue];
};