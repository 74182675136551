import "./ui-overlay.scss"
import ElginMarblesPhoto from '../../assets/images/elgin_marbles.jpeg';


export const ElginMarblesInfo = (props) => {
	return (
		<div className="elgin-marbles-inner">
			<div className="elgin-marbles-img-div">
				<img src={ElginMarblesPhoto} alt="Elgin Marbles" />
				<div className="modal-content-title">
					Elgin Marbles
				</div>
			</div>

			<div className="modal-content-divider" />
			<div className="modal-content-description">
				{description}
			</div>
		</div>
	)
}


const description = `The Elgin Marbles, a collection of Ancient Greek sculptures from the Parthenon and other structures from the Acropolis of Athens, were removed from Ottoman Greece to Britain by agents of Thomas Bruce, 7th Earl of Elgin, in the early 19th century. These sculptures, created in the 5th century BCE under the direction of sculptor and architect Phidias, were taken to Britain with the intention of establishing a private museum. The marbles were sold to the British government in 1816 and are now held in the British Museum, sparking longstanding international controversy. The Greek government has formally requested their return, arguing that they were obtained illegally and that their cultural value would be best appreciated in a unified public display with other Greek antiquities in the Acropolis Museum. The UK government and British Museum have refused their return.`