import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './lottie.scss'

export const LottieClapper = (props) => {
    const containerRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        if (animationRef.current) {

            if (props.play) {
                animationRef.current.play()
            } else {
                animationRef.current.goToAndStop(0)
            }
        }
    }, [props.play]);

    useEffect(() => {
        animationRef.current = lottie.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'assets/json/ClappyBlappy.json', // Replace with your Lottie JSON file path or URL
        });

        animationRef.current.setSpeed(1);

        // Resize the animation to fit the container on window resize
        const resizeHandler = () => {
            animationRef.current.resize();
        };

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <div ref={containerRef} className="lottie-svg-container" style={{ width: props?.style?.width ? props?.style?.width : '2rem', height: props?.style?.height ? props?.style?.height : '2rem' }} >

        </div>
    );
};