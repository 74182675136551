import { useControls } from "leva";

export const LevaCamera = (props) => {
	return useControls('Camera', {
		position: {
			value: props.position,
			min: -100,
			max: 100,
			step: 0.01
		},
		near: {
			value: props.near,
			min: 0,
			max: 100,
			step: 0.01
		},
		far: {
			value: props.far,
			min: 0,
			max: 1000,
			step: 0.01
		},
	},
		{
			collapsed: true
		})

}