import { Water } from 'three/examples/jsm/objects/Water.js'
import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { extend, useFrame } from '@react-three/fiber';
import { levaWater } from '../LevaDebug/LevaWater';
import { useTexture } from '@react-three/drei';

extend({ Water })
// const waterAsset = new THREE.TextureLoader().load('./../../../../assets/images/waterNormals.jpeg', function (texture) {
// 	texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
// })


export default function WaterShader(props) {
	const waterAsset = useTexture('./../../../../assets/images/waterNormals.jpeg', (texture) => {
		texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
	})


	WaterShader.defaultProps = {
		clipBias: 0.0,
		textureWidth: 2048,
		textureHeight: 2048,
		waterNormals: waterAsset,
		sunColor: [0.76, 0.54, 0.44],
		waterColor: [0.15, 0.51, 0.81],
		sunDirection: [75, -115, -250],
		distortionScale: 0.08,
		size: 17,
		speed: 0.1,
		alpha: 0.26
	}

	const waterRef = useRef();
	const waterGeometryRef = useRef()

	//map default props to new object, make waterColor and sunColor into THREE.Color objects
	//see sunColor, waterColor, sunDirection
	const waterProps = { ...WaterShader.defaultProps, ...props };

	// waterProps.waterColor = new THREE.Color(waterProps.waterColor);
	// waterProps.sunColor = new THREE.Color(waterProps.sunColor);

	//console log water to see more potential uniform values (there are a lot of them)

	if (waterGeometryRef.current == null) {
		waterGeometryRef.current = new Water(
			new THREE.PlaneGeometry(150, 80),
			waterProps
		);
	}

	var speedVal = props.speed //speed is read only, so we need to use a proxy variable

	const { sunColor, waterColor, sunDirection, alpha, distortionScale, size, speed } = levaWater(waterProps, waterRef, {
		value: waterProps.speed,
		step: 0.01,
		min: 0,
		max: 1,
		onChange: (v) => {
			speedVal = v
		}
	});

	useFrame((state, delta) => {
		waterGeometryRef.current.material.uniforms.time.value += delta * speedVal;
	});

	useEffect(() => {
		waterGeometryRef.current.material.uniforms.size.value = waterProps.size
		waterRef.current.material.toneMapped = true;
		waterRef.current.material.uniforms.sunDirection.y = -115;
	}, [waterGeometryRef.current])

	return (
		<>
			<primitive ref={waterRef} object={waterGeometryRef.current} position={[0, 1.04, -5]} rotation={[-Math.PI / 2, 0, 0]} />
		</>
	)
}
