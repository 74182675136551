import AmbientLight from "./Lights/AmbientLight"
import WaterShader from "./WaterShader"
import Triremes from "./Triremes/Triremes"
import Skybox from './Skybox'
import Temple from "./Temple"
import VideoSpotlight from "./Lights/VideoSpotlight"
import { useState, useEffect, useRef } from "react"
import { useThree } from "@react-three/fiber"
import gsap from 'gsap';
import MountainSprites from "./MountainSprites"
import Sizes from "../../../../hooks/Sizes"
import { Html, Sky } from "@react-three/drei"
import { useZStore } from "../../../../store/store"
import History from "./History/History"
import BehindTheScenes from "./BehindTheScenes/BehindTheScenes"
import { MainPageTitle } from "../../../UIOverlay/MainPageTitle"
import { BoxGroup } from "./BoxGroup"

export default function World(props) {
	const isMobile = useZStore(state => state.isMobile);

	const updateIsVideoPlayback = useZStore(state => state.updateIsVideoPlayback)
	const isVideoPlayback = useZStore(state => state.isVideoPlayback)
	const isLeavingVideoPlayback = useZStore(state => state.isLeavingVideoPlayback)
	const isAudioMuted = useZStore(state => state.isAudioMuted)
	const setIsAudioMuted = useZStore(state => state.setIsAudioMuted)

	const { camera } = useThree();
	const [cameraPos, setCameraPos] = useState(null);
	const [mobileState, setMobileState] = useState(true)
	const [intensity, setIntensity] = useState(0.4)
	const updateFrameloop = useZStore(state => state.updateFrameloop);
	const isPrevAudioMutedRef = useRef();
	// const updateShowModal = useZStore(state => state.updateShowModal)
	// const updateModalContent = useZStore(state => state.updateModalContent)

	// const handleInfoClick = () => {
	// 	updateModalContent('Info')
	// 	updateShowModal(true)
	// }

	useEffect(() => {
		const sizes = new Sizes();

		isPrevAudioMutedRef.current = isAudioMuted;

		if (sizes.width < 570) {
			setMobileState(true)
			setIntensity(1.5)
		}
	}, [])

	const playback = () => {
		if (!isMobile) {
			isPrevAudioMutedRef.current = isAudioMuted;
			updateIsVideoPlayback(true);
			setCameraPos({ x: camera.position.x, y: camera.position.y, z: camera.position.z })
			const dur = 1.5

			gsap.timeline()
				.to(camera.position,
					{
						duration: dur,
						x: -0.02,
						y: 1.34,
						z: -0.30,
						ease: 'power2.in',
						onComplete: () => {
							setIsAudioMuted(true)
							if (isVideoPlayback && !isLeavingVideoPlayback) {
								updateFrameloop('never')
							}
						},
					}
				)
		} else {
			isPrevAudioMutedRef.current = isAudioMuted;
			updateIsVideoPlayback(true);
			setCameraPos({ x: camera.position.x, y: camera.position.y, z: camera.position.z })
			gsap.timeline().to(camera.position,
				{
					duration: 1,
					z: 0.7,
					ease: 'power2.out',
					onComplete: () => {
						gsap.timeline()
							.to(camera.position, {
								duration: 0.5,
								z: 0.6,
								ease: 'power2.in',
							})
					}
				}
			);
			gsap.timeline()
				.to(camera.position,
					{

						duration: 1.5,
						x: -0.02,
						y: 1.34,
						onUpdate: () => {
							camera.lookAt(-0.018, 1.33, -0.357)
						},
					}
				)
				.then(() => {
					gsap.timeline()
						.to(camera.position,
							{
								duration: 1.5,
								x: -0.02,
								y: 1.34,
								z: -0.30,
								// ease: 'power2.in',
								onComplete: () => {
									setIsAudioMuted(true);
									if (isVideoPlayback && !isLeavingVideoPlayback) {
										updateFrameloop('never')
									}
								},
							}
						)
				})
		}
	}

	useEffect(() => {
		if (isLeavingVideoPlayback) {
			updateFrameloop('always');
			gsap.timeline().play()
				.to(camera.position,
					{
						duration: 2,
						x: cameraPos.x,
						y: cameraPos.y,
						z: cameraPos.z,
						ease: 'power.in',
						onStart: () => {
							if (isPrevAudioMutedRef.current === false) {
								setIsAudioMuted(false);
							}
						},
						onUpdate: () => {
							camera.lookAt(-0.018, 1.33, -0.357)
						}

					}
				)
		}
	}, [isLeavingVideoPlayback])

	return <>
		<AmbientLight intensity={intensity} />

		<VideoSpotlight />

		<MountainSprites />

		<Skybox />

		{/* <Sky
			sunPosition={[100, 20, 100]} inclination={0} azimuth={0.25}
		/> */}

		<Temple position={[0, 0.75, 0.5]} />

		<BoxGroup />

		<>
			<Html
				zIndexRange={[1000, 0]}
				center={true}
				position={[-0.018, 1.465, -0.36857]}
				style={{ padding: '1rem' }}
			></Html>

			<Html
				zIndexRange={[1000, 0]}
				center={true}
				position={[-0.017, 1.462, -0.36857]}
				style={{ padding: '1rem', width: '100vw' }}
			>
				<MainPageTitle />
			</Html>
		</>

		<BehindTheScenes />
		<History />


		<WaterShader />

		<Triremes />
	</>
}