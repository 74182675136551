import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import Sizes from '../../../../hooks/Sizes';


export const BoxGroup = () => {
    const groupRef = useRef();
    const { camera, size } = useThree();
    const widthBreakpoint = 700; // breakpoint for mobile vs desktop view
    const positionsRef = useRef({
        position: { x: 0.1265, y: 0.09, z: 0.02 },

        args: { width: 0.4, height: 0.12 },
    });

    useLayoutEffect(() => {
        const sizes = new Sizes();

        if (sizes.width > widthBreakpoint) {
            positionsRef.current = {
                position: { x: 0.1165, y: -0.029, z: 0.02 },
                args: { width: 0.2415, height: 0.0755 },
            };
        } else {

        }

        // console.log(positionsRef.current)
    }, [size]);

    return (
        <>
            <group ref={groupRef} position={[-0.135, 1.5, -0.375]}>
                <mesh position={[positionsRef.current.position.x, positionsRef.current.position.y, positionsRef.current.position.z]} >
                    <planeGeometry args={[positionsRef.current.args.width, positionsRef.current.args.height]} />
                    <meshPhysicalMaterial transparent alphaTest={0.8} >
                        <primitive attach="map" object={new TextureLoader().load("/assets/images/spartanDreamText.png")} />
                    </meshPhysicalMaterial>
                </mesh>



                {/* <mesh>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="red" />
                </mesh>
                <mesh position={[0.2375, 0, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="blue" />
                </mesh>
                <mesh position={[0, -0.055, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="green" />
                </mesh>
                <mesh position={[0.2375, -0.055, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="yellow" />
                </mesh> */}
                {/* 
                <mesh position={[-0.05, 0.175, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="red" />
                </mesh>
                <mesh position={[0.285, 0.175, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="blue" />
                </mesh>
                <mesh position={[-0.05, 0.05, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="green" />
                </mesh>
                <mesh position={[0.285, 0.05, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="yellow" />
                </mesh> */}






                {/* <mesh position={[0.1265, 0.09, 0.02]} >
                    <planeGeometry args={[0.4, 0.14]} />
                    <meshPhysicalMaterial transparent alphaTest={0.8} >
                        <primitive attach="map" object={new TextureLoader().load("/assets/images/spartanDreamText.png")} />
                    </meshPhysicalMaterial>
                </mesh> */}
            </group>
        </>
    );

};
