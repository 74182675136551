
import React, { useEffect, useState, useRef } from 'react'
import { Html } from '@react-three/drei'
import { WatchTrailer } from '../ThreeFiberCanvas/MelinasDreamExperience/World/SceneSelector/WatchTrailer/WatchTrailer'
import { SectionArrowsHome } from './SectionsArrowsHome'
import { useZStore } from '../../store/store'
import './ui-overlay.scss';
import zIndex from '@mui/material/styles/zIndex'
import { ElginMarblesInfo } from './ElginMarblesInfo'
import { ModalOuter } from './ModalOuter'
import { gsap } from 'gsap'
import { VaphioCupsInfo } from './VaphioCupsInfo'
import { BehindTheScenesInfo } from './BehindTheScenesInfo'
import { SceneSettings } from '../ThreeFiberCanvas/MelinasDreamExperience/World/SceneSelector/SceneSettings/SceneSettings'

export const UIOverlay = (props) => {
	const isMobile = useZStore(state => state.isMobile);

	const camera = props;
	const modalContent = useZStore((state) => state.modalContent);
	const modalOuterRef = useRef();
	const modalOuterRefAnimation = useRef();
	const overlayStuff = useRef();

	useEffect(() => {
		modalOuterRefAnimation.current = gsap.to(
			modalOuterRef.current,
			{
				duration: 0.5,
				autoAlpha: 1,
				ease: 'power2.inOut',
				paused: true,
			}
		)

		const unsubscribeSection = useZStore.subscribe(
			(state) => state.showModal,
			(value) => {
				if (value) {
					modalOuterRefAnimation.current.play();
				} else {
					modalOuterRefAnimation.current.reverse();
				}
			}
		)
		return () => {
			unsubscribeSection()
		}
	}, [])



	return <>
		<div style={{ width: '100vw', height: '100dvh', position: 'absolute' }}>
			{!isMobile ? <SectionArrowsHome camera={camera} /> : <></>}

			<ModalOuter ref={modalOuterRef} title={modalContent}>
				{
					modalContent === 'Info' ?
						<BehindTheScenesInfo />
						: modalContent === 'Elgin Marbles' ?
							<ElginMarblesInfo />
							:
							<VaphioCupsInfo />
				}
			</ModalOuter>

			<div style={{ opacity: 0 }} className='scene-selector' ref={overlayStuff}>
				<div className={'bottom-left'}>
					<SceneSettings introMusicRef={props.introMusicRef} whooshAudioRef={props.whooshAudioRef} ambientMusicRef={props.ambientMusicRef} initialAudioState={props.initialAudioState} />
				</div>
			</div>
		</div>
	</>
}