import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = (props) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '2rem' }}>
			<h1>(ಥ﹏ಥ)</h1>
			<h1>404 Not found</h1>
			<Link to='/' style={{ color: 'blue' }}>Return Home</Link>
		</div>
	)
}