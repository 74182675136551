import { useGLTF } from '@react-three/drei'
import Trireme from './Trireme';
import { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { useZStore } from '../../../../../store/store';

export default function Triremes(props) {
	const isMobile = useZStore(state => state.isMobile);
	const trireme = useGLTF('./assets/models/trireme/trireme4.glb');

	const trireme1 = createRef();
	const trireme2 = createRef();
	const trireme3 = createRef();

	function animateTrireme(trireme, optionsTo = {}) {
		gsap.to(trireme.position, {
			x: optionsTo.x,
			y: optionsTo.y,
			z: optionsTo.z,
			duration: optionsTo.duration,
		}, optionsTo.delay)
	}

	useEffect(() => {
		if (trireme1.current) {
			animateTrireme(trireme1.current, {
				x: 100,
				y: trireme1.current.position.y,
				z: trireme1.current.position.z,
				duration: 3000,
				delay: 0
			})
			animateTrireme(trireme2.current, {
				x: 100,
				y: trireme1.current.position.y,
				z: trireme1.current.position.z,
				duration: 3000,
				delay: 0
			})
			animateTrireme(trireme3.current, {
				x: 100,
				y: trireme1.current.position.y,
				z: trireme1.current.position.z,
				duration: 3000,
				delay: 0
			})
		}
	}, []);

	return (
		<>
			<Trireme
				model={trireme}
				inputRef={trireme1}
				position={isMobile ? [11, 1.01, -20] : [-12, 1.01, -20]}
				scale={[0.10, 0.1, 0.1]}
			/>
			<Trireme
				model={trireme}
				inputRef={trireme2}
				position={isMobile ? [11, 1.01, -27] : [-25, 1.01, -27]}
				scale={[0.10, 0.1, 0.1]}
			/>
			<Trireme
				model={trireme}
				inputRef={trireme3}
				position={isMobile ? [28, 1.01, -40] : [2, 1.01, -40]}
				scale={[0.10, 0.1, 0.1]}
			/>
		</>
	);
}

// position={[-12, 1.01, -20]}
// position={[-25, 1.01, -27]}
// position={[2, 1.01, -40]}
