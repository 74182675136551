import { Canvas } from '@react-three/fiber'
import { PerformanceMonitor, AdaptiveDpr, AdaptiveEvents, SoftShadows } from '@react-three/drei';
import { MelinasDreamExperience } from './MelinasDreamExperience/MelinasDreamExperience.jsx'
import { Camera } from './Camera/Camera.jsx'
import { Enter } from "./Enter/Enter.jsx"
import React, { useEffect, useRef, useState } from "react";
import { useZStore } from '../../store/store.js';
import { UIOverlay } from '../UIOverlay/UIOverlay.jsx';
import { CameraMobile } from './Camera/CameraMobile.jsx';

export default function ThreeFiberCanvas() {
	const cameraRef = useRef();
	const canvasRef = useRef();
	const [dpr, setDpr] = useState(1.5);
	const frameLoopBetter = useZStore(state => state.frameloop);
	const isMobile = useZStore(state => state.isMobile);

	return (
		<Enter camera={cameraRef} canvas={canvasRef}>
			<UIOverlay
				camera={cameraRef.current}
			/>
			<Canvas
				ref={canvasRef}
				shadows
				frameloop={frameLoopBetter}
				className="three-fiber-canvas"
				id="three-fiber-canvas"
				style={{ position: 'absolute', zIndex: -1 }}
				dpr={dpr}
			>
				<AdaptiveDpr pixelated />
				<AdaptiveEvents />
				<PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => setDpr(1)} >
					<Camera
						ref={cameraRef}
						// -0.018, 1.33, 0.55 // OG POSITION right in front
						// position={[-0.74, 1.65, 0.44]}
						// position={[-0.73, 1.53, 0.54]}
						// position={[-0.66, 1.53, 0.54]}
						// position={[-0.57, 1.53, 0.4]}
						position={[-0.018, 1.33, 0.3]}
						near={0.01}
						far={4500}
					/>
					{/* } */}

					<MelinasDreamExperience />

				</ PerformanceMonitor>
			</ Canvas>
		</Enter>

	)
}
