import { LevaAmbientLight } from "../../LevaDebug/LevaAmbientLight";
import { useRef } from "react";

export default function AmbientLight(props) {
	AmbientLight.defaultProps = {
		intensity: 1,
		color: '#ffffff'
	}

	const ambientLightRef = useRef();

	const lightProps = { ...AmbientLight.defaultProps, ...props };
	const { intensity, color } = LevaAmbientLight(ambientLightRef, lightProps);

	return <>
		<ambientLight
			ref={ambientLightRef}
			intensity={intensity}
			color={color}
		/>
	</>
}