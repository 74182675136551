import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './LocalStorage';
import axios from 'axios';
import { useSnackbar } from 'notistack';

axios.defaults.baseURL = import.meta.env.VITE_MELINAS_API;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const googleAuth = 0;
const localAuth = 1;
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [jwt, setJwt] = useLocalStorage('jwt', null);
	const navigate = useNavigate();

	const authenticateLogin = async (requestBody) => {
		return axios.post(`/Authentication/login`, JSON.stringify({ requestBody }))
			.then(res => {
				if (!res?.data || res?.status !== 200) {
					return null;
				}

				return res.data;
			})
			.catch((error) => {
				return null;
			});
	}

	const authenticatePass = async (body) => {
		return axios.post(`/Authentication/pass`, JSON.stringify(body), {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/json'
			}
		})
			.then(res => {
				if (!res?.data || res?.status !== 200) {
					return null;
				}

				return res.data;
			})
			.catch((error) => {
				return null;
			});
	}

	const handleAuthentication = (newJwt) => {
		if (!newJwt) {
			enqueueSnackbar('Invalid credentials.', { variant: 'error' });
			return false;
		} else {
			setJwt(newJwt);
			navigate('/');
			return true;
		}
	}

	const login = async (data) => {
		const requestBody = {
			authenticateType: localAuth,
			email: data.email,
			password: data.password,
			idToken: ''
		};

		return authenticateLogin(requestBody).then((newJwt) => handleAuthentication(newJwt));
	};

	const checkPass = async (data) => {
		const requestBody = {
			password: data.password
		};

		return authenticatePass(requestBody).then((newJwt) => handleAuthentication(newJwt));
	};

	const googleLogin = async (data) => {
		const requestBody = {
			authenticateType: googleAuth,
			email: null,
			password: null,
			idToken: data.credential
		};

		authenticateLogin(requestBody).then((newJwt) => handleAuthentication(newJwt));
	};

	const logout = () => {
		setJwt(null);
		navigate('/login');
	};

	const value = useMemo(() => ({ jwt, login, googleLogin, logout, checkPass }), [jwt]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
