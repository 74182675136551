import { useGLTF } from '@react-three/drei'

export default function MountainSprites(props) {

    const islands = useGLTF('../../../../assets/models/island2.glb')

    return <>
        <primitive
            object={islands.scene}
            position={[27, 1.03, -40]}
            scale={[25, 12, 15]}
            mater
        >
            <meshBasicMaterial attach='material'/>
        </primitive>
    </>

}
