import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const useZStore = create(subscribeWithSelector((set, get) => {
	return {
		// isMobile: window.innerWidth <= 550,
		isMobile: false,

		sectionOptions: ['info', 'home', 'history'],

		section: 'intro',
		updateSection: (section) => set({ section }),

		frameloop: 'never',
		updateFrameloop: (frameloop) => set({ frameloop }),

		quality: 'medium',
		updateQuality: (quality) => set({ quality }),

		isAudioMuted: true,
		setIsAudioMuted: (isAudioMuted) => set({ isAudioMuted }),

		isVideoPlayback: false,
		updateIsVideoPlayback: (isVideoPlayback) => set({ isVideoPlayback }),
		isLeavingVideoPlayback: false,
		updateIsLeavingVideoPlayback: (isLeavingVideoPlayback) => set({ isLeavingVideoPlayback }),

		// Seems unused mostly
		showUI: false,
		updateShowUI: (showUI) => set({ showUI }),

		//Section modals
		showModal: false,
		updateShowModal: (showModal) => set({ showModal }),

		modalContent: 'default', //can be 'Elgin Marbles', 'Vaphio Cups', 'Info', or 'default'
		updateModalContent: (modalContent) => set({ modalContent }),

		// isOdysseyPresentsMounted: false,
		isOdysseyPresentsMounted: true,
		updateIsOdysseyPresentsMounted: (isOdysseyPresentsMounted) => set({ isOdysseyPresentsMounted }),

		animateOdysseyPresentsTimeline: null,
		updateAnimateOdysseyPresentsTimeline: (animateOdysseyPresentsTimeline) => set({ animateOdysseyPresentsTimeline }),
		animateMelinasDreamTimeline: null,
		updateAnimateMelinasDreamTimeline: (animateMelinasDreamTimeline) => set({ animateMelinasDreamTimeline }),
		cameraFlyInTimeline: null,
		updateCameraFlyInTimeline: (cameraFlyInTimeline) => set({ cameraFlyInTimeline }),
		animateSceneSelectorTimeline: null,
		updateAnimateSceneSelectorTimeline: (animateSceneSelectorTimeline) => set({ animateSceneSelectorTimeline }),
	}
}))

