import './svgs.scss';

export const SoundOn = () => (
	<svg className="sound__on" viewBox="0 0 122.88 115.67" xmlns="http://www.w3.org/2000/svg" height="100%" preserveAspectRatio="xMidYMid meet">
		<defs>
			<filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
				<feDropShadow dx="-4" dy="4" stdDeviation="4" floodColor="rgba(0, 0, 0, 1)" />
			</filter>
		</defs>
		<g filter="url(#shadow)">
			<path fill="#ffffff" d="M6.71,27.55h27.4c0.58,0,1.04,0.47,1.04,1.04v59.28c0,0.57-0.47,1.04-1.04,1.04H6.7c-3.68,0-6.7-3.01-6.7-6.7 V34.26C0,30.57,3.02,27.55,6.71,27.55L6.71,27.55z M105.96,32.65c-0.22-0.32-0.45-0.63-0.68-0.94l0.04-0.03l-0.11-0.17l-0.88-1.3 l1.26-0.93l5.23-2.51l1.37-1.02l0.96,1.42c3.56,5.22,6.26,11.07,7.89,17.3c1.58,6.01,2.17,12.39,1.62,18.94 c-0.43,5.05-1.52,9.89-3.17,14.44c-1.71,4.69-4.03,9.08-6.85,13.08l-1.22,1.73l-1.37-1.61l-4.68-3.31l-0.81-0.95l0.7-1.03 c2.27-3.36,4.13-7.04,5.51-10.96c1.33-3.78,2.21-7.81,2.57-12.02c0.48-5.65-0.03-11.15-1.37-16.33 C110.69,41.52,108.64,36.87,105.96,32.65L105.96,32.65z M41.89,26.46C57.86,17.7,73.83,8.94,89.79,0.18 c1.53-0.84,3.18,1.43,3.18,3.18v108.77c0,1.84-1.74,4.25-3.35,3.35L41.89,88.56c-0.3-0.17-0.64-0.29-0.64-0.64V27.1 C41.26,26.75,41.59,26.63,41.89,26.46L41.89,26.46z" />
		</g>
	</svg>
)


