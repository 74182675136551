import { useControls } from "leva";

export const levaWater = (waterProps, water, speedControl) => {
	return useControls('Water', {
		sunColor: {
			value: waterProps.sunColor,
			step: 0.01,
			onChange: (v) => {
				water.current.material.uniforms.sunColor.value = v
			}
		},
		waterColor: {
			value: waterProps.waterColor,
			step: 0.01,
			onChange: (v) => {
				water.current.material.uniforms.waterColor.value = v
			}
		},
		sunDirection: {
			value: waterProps.sunDirection,
			min: -200,
			max: 200,
			step: 1.0,
			onChange: (v) => {
				water.current.material.uniforms.sunDirection.value = v
			}
		},
		alpha: {
			value: waterProps.alpha,
			min: 0,
			max: 1,
			step: 0.01,
			onChange: (v) => {
				water.current.material.uniforms.alpha.value = v
			}
		},
		distortionScale: {
			value: waterProps.distortionScale,
			step: 0.01,
			min: -4,
			max: 4,
			onChange: (v) => {
				water.current.material.uniforms.distortionScale.value = v
			}
		},
		size: {
			value: waterProps.size,
			step: 0.01,
			min: 0,
			max: 100,
			onChange: (v) => {
				water.current.material.uniforms.size.value = v
			}
		},
		speedControl,
	},
		{ collapsed: true })
}
