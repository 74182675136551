import { LevaCamera } from "../MelinasDreamExperience/LevaDebug/LevaCamera";
import Sizes from "../../../hooks/Sizes";
import gsap from "gsap";
import { PerspectiveCamera } from "@react-three/drei";
import { forwardRef, useEffect, useLayoutEffect } from "react";
import { useRef } from "react";
import { useZStore } from "../../../store/store";
// import useAudio from "../../../store/useAudio";
import { Sphere } from "@react-three/drei";


const CameraMobile = forwardRef((props, ref) => {
	const updateCameraFlyInTimeline = useZStore(state => state.updateCameraFlyInTimeline);
	const setShowSectionArrows = useZStore(state => state.setShowSectionArrows);
	const updateSection = useZStore(state => state.updateSection);
	// const { playAudio } = useAudio();

	useLayoutEffect(() => {
		const sizes = new Sizes();
		const widthBreakpoint = 660; // breakpoint for mobile vs desktop view

		const zPosition = sizes.width < widthBreakpoint ? 0.55 : 0.12
		const timeline = gsap.timeline().pause();

		timeline.add(gsap.to(
			ref.current.position,
			{
				duration: 0.3,
				// x: -0.018,
				// y: 1.33,
				// z: zPosition,
				// ease: 'power1.inOut',
				// onStart: () => playAudio('ambient', true),
				onComplete: () => {
					// ref.current.lookAt(cameraTarget.current.position)
					// updateSection('home')
				}
			}
		));

		updateCameraFlyInTimeline(timeline);
	}, [])

	const sectionCoords = {
		info: {
			x: -0.75,
			y: 1.30,
			z: -0.15
		},
		home: {
			x: -0.018,
			y: 1.33,
			z: 0.12
		},
		history: {
			x: 0.75,
			y: 1.28,
			z: -0.23
		}
	}
	const sectionTargets = {
		info: {
			x: -0.55,
			y: 1.25,
			z: -0.6
		},
		home: {
			x: -0.018,
			y: 1.33,
			z: -0.357
		},
		history: {
			x: 0.75,
			y: 1.26,
			z: -0.32
		}
	}

	// useEffect(() => {
	// 	const unsubscribeIsAudioMuted = useZStore.subscribe(
	// 		(state) => state.section,
	// 		(value) => {
	// 			if (value == 'home') {
	// 				gsap.to(ref.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionCoords[value],
	// 						ease: 'power2.inOut',
	// 					})
	// 				gsap.to(cameraTarget.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionTargets[value],
	// 						ease: 'power2.inOut',
	// 						onUpdate: () => {
	// 							ref.current.lookAt(cameraTarget.current.position)
	// 						}
	// 					})
	// 			}
	// 			if (value == 'info') {
	// 				gsap.to(ref.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionCoords[value],
	// 						ease: 'power2.inOut',
	// 					})
	// 				gsap.to(cameraTarget.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionTargets[value],
	// 						ease: 'power2.inOut',
	// 						onUpdate: () => {
	// 							ref.current.lookAt(cameraTarget.current.position)
	// 						}
	// 					})
	// 			}
	// 			if (value == 'history') {
	// 				gsap.to(ref.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionCoords[value],
	// 						ease: 'power2.inOut',

	// 					})
	// 				gsap.to(cameraTarget.current.position,
	// 					{
	// 						duration: 1.5,
	// 						...sectionTargets[value],
	// 						ease: 'power2.inOut',
	// 						onUpdate: () => {
	// 							ref.current.lookAt(cameraTarget.current.position)
	// 						}
	// 					})
	// 			}


	// 		}
	// 	)
	// 	return () => {
	// 		unsubscribeIsAudioMuted()
	// 	}
	// }, [])

	const sizes = new Sizes();

	let currentSizeState = 'normal'

	if (sizes.width < 570) {
		currentSizeState = 'small'
	}

	// sizes.on('resize', () => {
	// 	if (sizes.width < 570) {
	// 		if (currentSizeState == 'normal') {
	// 			currentSizeState = 'small'
	// 			gsap.timeline().to(
	// 				ref.current.position,
	// 				{
	// 					duration: 1.5,
	// 					x: -0.018,
	// 					y: 1.33,
	// 					z: 0.55,
	// 					ease: 'power1.inOut',
	// 				}
	// 			);

	// 		}
	// 	}
	// 	if (sizes.width >= 570) {
	// 		if (currentSizeState == 'small') {
	// 			currentSizeState = 'normal'
	// 			gsap.timeline().to(
	// 				ref.current.position,
	// 				{
	// 					duration: 1.5,
	// 					x: -0.018,
	// 					y: 1.33,
	// 					z: 0.12,
	// 					ease: 'power1.inOut',
	// 				}
	// 			);
	// 		}
	// 	}
	// })

	CameraMobile.defaultProps = {
		position: [10, 0, 0],
		fov: 75,
		near: 0.1,
		far: 1000
	}

	const cameraTarget = useRef();

	const { position, near, far } = LevaCamera(props)

	useEffect(() => {
		//REAL GOOD
		// ref.current.lookAt(-0.018, 1.33, -0.357)
		ref.current.lookAt(-0.03, 1.33, -0.357)
	}, [])

	// useLayoutEffect(() => {
	// 	ref.current.lookAt(-0.018, 1.33, -0.357)
	// 	// ref.current.lookAt(-0.018, 1.33, -0.357)
	// }, [])

	return <>
		<PerspectiveCamera
			makeDefault
			ref={ref}
			position={position}
			aspect={sizes.width / sizes.height}
			near={near}
			far={far}
			onUpdate={self => self.updateProjectionMatrix()}
		>
			<object3D ref={cameraTarget} position={[-0.018, 1.33, -0.357]} />
		</PerspectiveCamera>
		<mesh position={[sectionTargets['info'].x, sectionTargets['info'].y, sectionTargets['info'].z]}>
			<sphereGeometry args={[0.01, 16, 14]} />
			<meshBasicMaterial color={'red'} />
		</mesh>
	</>
})

export { CameraMobile }
