import "./ui-overlay.scss"
import VaphioCupsPhoto from '../../assets/images/vaphio_cups.jpeg';

export const VaphioCupsInfo = (props) => {
	return (
		<div className="vaphio-cups-inner">
			<div className="vaphio-cups-img-div">
				<img src={VaphioCupsPhoto} alt="Vaphio Cups" />
				<div className="modal-content-title">
					Vaphio Cups
				</div>
			</div>

			<div className="modal-content-divider" />
			<div className="modal-content-description">
				{description}
			</div>
		</div>
	)
}

const description = `Vaphio, an ancient site in Laconia, Greece, is renowned for its tholos or beehive tomb, excavated in 1889. Artifacts discovered there, including a large number of carved gem seals, amethyst beads, and articles in various materials, were transferred to the National Archaeological Museum of Athens. The most notable finds are a pair of gold Vaphio Cups, decorated with relief scenes depicting two different methods of capturing bulls. The cups, believed to be the finest examples of Mycenaean or Minoan art, exhibit stylistic differences suggesting that one might have been produced in mainland Greece while the other was imported from Crete. The term "Vapheio cup" is now used in Aegean archaeology to describe the shape of these gold cups.`