import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from "react";
import { useZStore } from '../../store/store.js';
import { gsap } from "gsap";
import './ui-overlay.scss'
import { LottieHistory } from "../../assets/json/LottieHistory.jsx";
import { LottieClapper } from "../../assets/json/LottieClapper.jsx";
import { LottiePillar } from "../../assets/json/LottiePillar.jsx";

export const SectionArrowsHome = () => {
    const section = useZStore((state) => state.section)
    const updateSection = useZStore((state) => state.updateSection)
    const updateShowModal = useZStore((state) => state.updateShowModal)

    const refs = useRef({
        homeIconRight: null,
        homeIconLeft: null,
        historyIcon: null,
        infoIcon: null,
        arrowRight: null,
        arrowLeft: null
    });

    const animations = useRef({
        homeIconLeftAnimation: null,
        homeIconRightAnimation: null,
        infoIconAnimation: null,
        historyIconAnimation: null,
        arrowRightAnimation: null,
        arrowLeftAnimation: null
    });

    const sectionStateRef = useRef(section);

    const handlePrevClick = useCallback(() => {
        if (sectionStateRef.current === 'home') {
            updateSection('info');
        } else if (sectionStateRef.current === 'history') {
            updateSection('home');
        }
    }, [updateSection]);

    const handleNextClick = useCallback(() => {
        if (sectionStateRef.current === 'home') {
            updateSection('history');
        } else if (sectionStateRef.current === 'info') {
            updateSection('home');
        }
    }, [updateSection]);

    useEffect(() => {
        const handleScroll = (function () {
            let timer = false;
            return function (e) {
                if (!timer) {
                    if (e.deltaY < 0) {
                        handlePrevClick();
                    } else if (e.deltaY > 0) {
                        handleNextClick();
                    }
                }
                clearTimeout(timer);
                timer = setTimeout(() => {
                    timer = null;
                }, 200);
            };
        })();

        const unsubscribeSection = useZStore.subscribe(
            (state) => state.section,
            (value) => {
                sectionStateRef.current = value;
                window.addEventListener('wheel', handleScroll);
                if (value === 'home') {
                    updateShowModal(false);
                    animations.current.homeIconLeftAnimation.reverse();
                    animations.current.homeIconRightAnimation.reverse();
                    animations.current.infoIconAnimation.play();
                    animations.current.historyIconAnimation.play();
                    animations.current.arrowRightAnimation.play();
                    animations.current.arrowLeftAnimation.play();
                } else if (value === 'history') {
                    updateShowModal(false);
                    animations.current.homeIconLeftAnimation.play();
                    animations.current.homeIconRightAnimation.reverse();
                    animations.current.infoIconAnimation.reverse();
                    animations.current.historyIconAnimation.reverse();
                    animations.current.arrowRightAnimation.reverse();
                    animations.current.arrowLeftAnimation.play();
                } else if (value === 'info') {
                    updateShowModal(false);
                    animations.current.homeIconLeftAnimation.reverse();
                    animations.current.homeIconRightAnimation.play();
                    animations.current.infoIconAnimation.reverse();
                    animations.current.historyIconAnimation.reverse();
                    animations.current.arrowRightAnimation.play();
                    animations.current.arrowLeftAnimation.reverse();
                }
            }
        );

        return () => {
            window.removeEventListener('wheel', handleScroll);
            unsubscribeSection();
        };
    }, [handleNextClick, handlePrevClick, updateShowModal]);

    useLayoutEffect(() => {
        animations.current.homeIconLeftAnimation = gsap.timeline().to(refs.current.homeIconLeft, {
            opacity: 1,
            duration: 1,
        }).paused(true);

        animations.current.homeIconRightAnimation = gsap.timeline().to(refs.current.homeIconRight, {
            opacity: 1,
            duration: 1
        }).paused(true);

        animations.current.infoIconAnimation = gsap.timeline().to(refs.current.infoIcon, {
            opacity: 1,
            duration: 1,
        }).paused(true);

        animations.current.historyIconAnimation = gsap.timeline().to(refs.current.historyIcon, {
            opacity: 1,
            duration: 1,
        }).paused(true);

        animations.current.arrowRightAnimation = gsap.timeline().to(refs.current.arrowRight, {
            opacity: 0.8,
            duration: 1
        }).paused(true);

        animations.current.arrowLeftAnimation = gsap.timeline().to(refs.current.arrowLeft, {
            opacity: 0.8,
            duration: 1
        }).paused(true);
    }, []);

    const [playLottieHistory, setPlayLottieHistory] = useState(false);
    const [playLottieClapper, setPlayLottieClapper] = useState(false);

    return (
        <div className="section-arrows">
            <div className={`section-arrows button-left`}>
                <div className="btn" onClick={handlePrevClick}
                    style={{
                        transform: 'rotate(180deg)',
                        cursor: refs.current.arrowLeft?.style?.opacity == 0 ? 'default' : 'pointer'
                    }}
                    onMouseEnter={() => { setPlayLottieClapper(true) }} onMouseLeave={() => { setPlayLottieClapper(false) }}>
                    <div className="img-container">
                        <div className="img-container-inner-left">
                            <div ref={(el) => refs.current.infoIcon = el} style={{ transform: 'rotate(180deg)', position: 'relative', opacity: 0 }}>
                                <LottieClapper style={{ width: '6rem', height: '6rem', position: "absolute", top: 0, bottom: 0 }} play={playLottieClapper} />
                                <div className="section-arrows-text" style={{ bottom: '-32%' }}>
                                    About
                                </div>
                            </div>
                        </div>
                        <div className={"img-container-inner-left"} style={{ transform: 'rotate(180deg)' }} >
                            <div ref={(el) => refs.current.homeIconLeft = el} style={{ position: 'relative', opacity: 0 }} >
                                <LottiePillar style={{ width: '6rem', height: '6rem', position: "absolute", top: 0, bottom: 0 }} />
                                <div className="section-arrows-text">Home</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`section-arrows button-right`} >
                <div className="btn" onClick={handleNextClick}
                    style={{
                        cursor: refs.current.arrowRight?.style?.opacity == 0 ? 'default' : 'pointer'
                    }}
                    onMouseEnter={() => { setPlayLottieHistory(true) }} onMouseLeave={() => { setPlayLottieHistory(false) }}>
                    <div className="img-container">
                        <div className="img-container-inner-right">
                            <div ref={(el) => refs.current.historyIcon = el} style={{ position: 'relative', opacity: 0 }} >
                                <LottieHistory style={{ width: '6rem', height: '6rem', position: "absolute", top: 0, bottom: 0 }} play={playLottieHistory} />
                                <p className="section-arrows-text">History</p>
                            </div>
                        </div>
                        <div className={"img-container-inner-right"}>
                            <div ref={(el) => refs.current.homeIconRight = el} style={{ position: 'relative', opacity: 0 }} >
                                <LottiePillar style={{ width: '6rem', height: '6rem', position: "absolute", top: 0, bottom: 0 }} />
                                <div className="section-arrows-text">Home</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

