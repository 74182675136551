import "./ui-overlay.scss";
import { getVideoSource } from "../../util/azure-video";

export const BehindTheScenesInfo = (props) => {
	const videoSource = getVideoSource('wip.mp4');

	return (
		<div className="behind-the-scenes-inner" >
			<video controls width="100%" style={{ borderRadius: '6px', borderBottomLeftRadius: '6px !important', borderBottomRightRadius: '6px !important', paddingRight: '4px', paddingLeft: '4px', marginTop: '4px' }}>
				<source src={videoSource} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<div className="modal-content-description">
				{description}
			</div>
		</div>
	);
};

const description = `This footage shows the spirit that inspired the production of "A Spartan Dream." Pictured here is Leonidas Demas, the executive producer, as a young boy in the late 1950s. The time when he searched for the essence of what it means to be a Spartan.`;
